import type { DeepReadonly } from '@modules/utils/utilityTypes';

type BaseAppConfig = DeepReadonly<{
  API_ROOT: string;
  APOLLO_CLIENT_DEV_TOOLS: boolean;
  CREATIVE_CDN: string;
  CREATIVE_VERSIONS: string;
  DD_APP_ID: string;
  DD_CLIENT_TKN: string;
  DD_ENV: 'local' | string;
  DD_SITE: string;
  DD_SAMPLE_RATE: number;
  DD_SESSION_REPLAY_SAMPLE_RATE: number;
  DD_TRACK_USER_INTERACTIONS: boolean;
  FEATURES_BUCKET_URL: string;
  INTERCOM_APP_ID: string; // use empty string to disable
  INTERCOM_MESSENGER_ENABLED: boolean;
  KEYCLOAK_CLIENT_ID: string;
  KEYCLOAK_REALM: string;
  KEYCLOAK_URL: string;
  MAPBOX_TOKEN: string;
  MAPBOX_URL: string;
  MF_REMOTE_ADCREATOR_URL: string;
  MF_REMOTE_BOOKING_URL: string;
  MF_REMOTE_MAP_URL: string;
  MF_REMOTE_REPORTING_URL: string;
  MF_REMOTE_PERSONAL_SETTINGS_URL: string;
  MF_REMOTE_ORGANIZATION_SETTINGS_URL: string;
  ORG_ID: string;
  PREVIEW_F11_HOST: string;
  PREVIEW_NEUTRAL_HOST: string;
  STAGE_HOST_LIVE: string;
  STAGE_HOST_PREVIEW: string;
  SUPPORTED_LANGUAGES: string;
  TEMP_ADCREATOR_LIVE_RENDERING: boolean;
  TEMP_ADCREATOR_PRODUCT_FEED: boolean;
  THEME_DEFAULT?: string; // https://factor-eleven.atlassian.net/browse/FOUN-948: deprecate this variable
  THEME_URL: string;
  TRANSLATION_URL: string;
  YOUTUBE_API_KEY: string;
}>;

type InternalServiceUrls = DeepReadonly<{
  FILE_URL: string;
  IMG_URL: string;
  DOWNLOAD_URL: string;
  EXPORT_URL: string;
}>;

export type AppConfig = BaseAppConfig & InternalServiceUrls;

let appConfig: AppConfig;

const appConfigRequiredKeys: { [P in keyof BaseAppConfig]: true } = {
  API_ROOT: true,
  APOLLO_CLIENT_DEV_TOOLS: true,
  CREATIVE_CDN: true,
  CREATIVE_VERSIONS: true,
  DD_APP_ID: true,
  DD_CLIENT_TKN: true,
  DD_ENV: true,
  DD_SITE: true,
  DD_SAMPLE_RATE: true,
  DD_SESSION_REPLAY_SAMPLE_RATE: true,
  DD_TRACK_USER_INTERACTIONS: true,
  FEATURES_BUCKET_URL: true,
  INTERCOM_APP_ID: true,
  INTERCOM_MESSENGER_ENABLED: true,
  KEYCLOAK_CLIENT_ID: true,
  KEYCLOAK_REALM: true,
  KEYCLOAK_URL: true,
  MAPBOX_TOKEN: true,
  MAPBOX_URL: true,
  MF_REMOTE_ADCREATOR_URL: true,
  MF_REMOTE_BOOKING_URL: true,
  MF_REMOTE_MAP_URL: true,
  MF_REMOTE_REPORTING_URL: true,
  MF_REMOTE_PERSONAL_SETTINGS_URL: true,
  MF_REMOTE_ORGANIZATION_SETTINGS_URL: true,
  ORG_ID: true,
  PREVIEW_F11_HOST: true,
  PREVIEW_NEUTRAL_HOST: true,
  STAGE_HOST_LIVE: true,
  STAGE_HOST_PREVIEW: true,
  SUPPORTED_LANGUAGES: true,
  TEMP_ADCREATOR_LIVE_RENDERING: true,
  TEMP_ADCREATOR_PRODUCT_FEED: true,
  THEME_URL: true,
  TRANSLATION_URL: true,
  YOUTUBE_API_KEY: true,
};

// https://factor-eleven.atlassian.net/browse/AR-2464
// TODO: Refactor this module and remove legacy pattern of fetching
// the app configuration, the configuration will always come from
// the window object.
// We can try to remove the loadAppConfig function and load and assert the
// app config the first time that getAppConfig is called.
export function assertInjectedAppConfig(): void {
  // if check for backwards compatibility
  if (window.appConfig) {
    const injectedAppConfig = window.appConfig;
    if (!injectedAppConfig || typeof injectedAppConfig !== 'object') {
      throw new Error(
        `Injected app configuration has the wrong format, app config value: ${injectedAppConfig}`,
      );
    }
    const missingAppConfigVariables: string[] = [];
    Object.keys(appConfigRequiredKeys).forEach((key) => {
      if (!(key in injectedAppConfig)) {
        missingAppConfigVariables.push(key);
      }
    });
    if (missingAppConfigVariables.length > 0) {
      throw new Error(
        `Injected app configuration is missing env variables, missing variables in app config: ${missingAppConfigVariables.join(
          ', ',
        )}`,
      );
    }
  }
}

export function loadAppConfigOverwrites(): Partial<BaseAppConfig> {
  const appConfigOverwrite = localStorage.getItem('DEBUG_APP_CONFIG_OVERWRITE');

  if (appConfigOverwrite) {
    try {
      const partialAppConfig = JSON.parse(appConfigOverwrite);
      if (partialAppConfig && typeof partialAppConfig === 'object') {
        console.warn('DEBUG_APP_CONFIG_OVERWRITE applied', partialAppConfig);
        return partialAppConfig;
      }
    } catch (_e) {
      console.info('DEBUG_APP_CONFIG_OVERWRITE ignored, it has an invalid shape', {
        appConfigOverwrite,
      });
    }
  }

  return {};
}

const initAppConfig = (): BaseAppConfig => {
  if (window.appConfig) {
    return { ...(window.appConfig as BaseAppConfig), ...loadAppConfigOverwrites() };
  }

  const envConfig: BaseAppConfig = {
    API_ROOT: import.meta.env.VITE_API_ROOT,
    APOLLO_CLIENT_DEV_TOOLS: import.meta.env.VITE_APOLLO_CLIENT_DEV_TOOLS === 'true',
    CREATIVE_CDN: import.meta.env.VITE_CREATIVE_CDN,
    CREATIVE_VERSIONS: import.meta.env.VITE_CREATIVE_VERSIONS,
    DD_APP_ID: import.meta.env.VITE_DD_APP_ID,
    DD_CLIENT_TKN: import.meta.env.VITE_DD_CLIENT_TKN,
    DD_ENV: import.meta.env.VITE_DD_ENV,
    DD_SITE: import.meta.env.VITE_DD_SITE,
    DD_SAMPLE_RATE: Number(import.meta.env.VITE_DD_SAMPLE_RATE),
    DD_SESSION_REPLAY_SAMPLE_RATE: Number(import.meta.env.VITE_DD_SESSION_REPLAY_SAMPLE_RATE),
    DD_TRACK_USER_INTERACTIONS: import.meta.env.VITE_DD_TRACK_USER_INTERACTIONS === 'true',
    FEATURES_BUCKET_URL: import.meta.env.VITE_FEATURES_BUCKET_URL,
    INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
    INTERCOM_MESSENGER_ENABLED: import.meta.env.VITE_INTERCOM_MESSENGER_ENABLED === 'true',
    KEYCLOAK_CLIENT_ID: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
    KEYCLOAK_REALM: import.meta.env.VITE_KEYCLOAK_REALM,
    KEYCLOAK_URL: import.meta.env.VITE_KEYCLOAK_URL,
    MAPBOX_TOKEN: import.meta.env.VITE_MAPBOX_TOKEN,
    MAPBOX_URL: import.meta.env.VITE_MAPBOX_URL,
    MF_REMOTE_ADCREATOR_URL: import.meta.env.VITE_MF_REMOTE_ADCREATOR_URL,
    MF_REMOTE_BOOKING_URL: import.meta.env.VITE_MF_REMOTE_BOOKING_URL,
    MF_REMOTE_MAP_URL: import.meta.env.VITE_MF_REMOTE_MAP_URL,
    MF_REMOTE_REPORTING_URL: import.meta.env.VITE_MF_REMOTE_REPORTING_URL,
    MF_REMOTE_PERSONAL_SETTINGS_URL: import.meta.env.VITE_MF_REMOTE_PERSONAL_SETTINGS_URL,
    MF_REMOTE_ORGANIZATION_SETTINGS_URL: import.meta.env.VITE_MF_REMOTE_ORGANIZATION_SETTINGS_URL,
    ORG_ID: import.meta.env.VITE_ORG_ID,
    PREVIEW_F11_HOST: import.meta.env.VITE_PREVIEW_F11_HOST,
    PREVIEW_NEUTRAL_HOST: import.meta.env.VITE_PREVIEW_NEUTRAL_HOST,
    STAGE_HOST_LIVE: import.meta.env.VITE_STAGE_HOST_LIVE,
    STAGE_HOST_PREVIEW: import.meta.env.VITE_STAGE_HOST_PREVIEW,
    SUPPORTED_LANGUAGES: import.meta.env.VITE_SUPPORTED_LANGUAGES,
    TEMP_ADCREATOR_LIVE_RENDERING: import.meta.env.VITE_TEMP_ADCREATOR_LIVE_RENDERING === 'true',
    TEMP_ADCREATOR_PRODUCT_FEED: import.meta.env.VITE_TEMP_ADCREATOR_PRODUCT_FEED === 'true',
    THEME_URL: import.meta.env.VITE_THEME_URL,
    TRANSLATION_URL: import.meta.env.VITE_TRANSLATION_URL,
    YOUTUBE_API_KEY: import.meta.env.VITE_YOUTUBE_API_KEY,
  };

  return {
    ...envConfig,
    ...loadAppConfigOverwrites(),
  };
};

const deriveServiceConfig = (baseAppConfig: BaseAppConfig): AppConfig => {
  if (!baseAppConfig.API_ROOT) {
    return {
      ...baseAppConfig,
      DOWNLOAD_URL: '/ad-manager/download/',
      EXPORT_URL: '/ad-manager/export/',
      FILE_URL: '/ad-manager/file/',
      IMG_URL: '/ad-manager/image/',
    };
  }
  return {
    ...baseAppConfig,
    DOWNLOAD_URL: String(new URL('/ad-manager/download/', baseAppConfig.API_ROOT)),
    EXPORT_URL: String(new URL('/ad-manager/export/', baseAppConfig.API_ROOT)),
    FILE_URL: String(new URL('/ad-manager/file/', baseAppConfig.API_ROOT)),
    IMG_URL: String(new URL('/ad-manager/image/', baseAppConfig.API_ROOT)),
  };
};
export function loadAppConfig() {
  const config = initAppConfig();
  appConfig = Object.freeze(deriveServiceConfig(config));
}

export function getAppConfig() {
  if (!appConfig) {
    throw new Error('getAppConfig() called before loadAppConfig()');
  }
  return appConfig;
}
