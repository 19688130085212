import { type LogsInitConfiguration, datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum'; // Datadog RUM (real user monitoring) module
import { getAppConfig } from '@modules/config/appConfig';

export function initDataDog() {
  const config = getAppConfig();

  // app config has not been validated at this point, so we need to be defensive
  const applicationId = config?.DD_APP_ID;
  const clientToken = config?.DD_CLIENT_TKN;
  const env = config?.DD_ENV;
  const site = config?.DD_SITE as LogsInitConfiguration['site'];
  const sampleRate = config?.DD_SAMPLE_RATE;
  const sessionReplaySampleRate = config?.DD_SESSION_REPLAY_SAMPLE_RATE;
  const trackUserInteractions = config?.DD_TRACK_USER_INTERACTIONS;
  // https://factor-eleven.atlassian.net/browse/AR-2464
  // DD_VERSION comes from the legacy deploy flow, we can remove it
  // if we are able to deprecate the legacy flow.
  // VITE_APP_VERSION will be provided by the CI build script.
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const version = import.meta.env.VITE_APP_VERSION ?? (config as any)?.DD_VERSION;

  if (!applicationId || !clientToken) {
    return;
  }

  const commonDatadogProps = {
    clientToken,
    env,
    site,
    service: 'adm-ui',
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sampleRate,
  };

  datadogLogs.init(commonDatadogProps);

  // Datadog RUM (real user monitoring) integration code
  datadogRum.init({
    ...commonDatadogProps,
    applicationId,
    sessionReplaySampleRate,
    trackUserInteractions,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event) => {
      event.view.url = event.view.url.replace('#/', '');
      return true;
    },
    allowedTracingUrls: [
      (url: string) => {
        const supportedRegexList = [
          // Local environments
          /http(s)?:\/\/localhost:.*/,
          // Review environments
          /http(s)?:\/\/.+.review.factor-eleven.com.*/,
          // Development environment
          /http(s)?:\/\/admanager-gateway-dev.factor-eleven.com.*/,
          // Production environment
          /http(s)?:\/\/admanager-gateway.factor-eleven.com.*/,
        ];
        return supportedRegexList.some((reg) => reg.test(url));
      },
    ],
  });

  datadogRum.startSessionReplayRecording();
}
